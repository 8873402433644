#ctaWpp {
    background-color: #15BE63;
    font-weight: 700;
    padding: .5rem 1.5rem;
    border-radius: 5px;
    height: 7vh;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaWpp a figure {
    width: 13%;
}

#ctaWpp a figure img {
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        height: 4vh;
    }

    #ctaWpp a figure {
        width: 10%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}