#about .about1 {
  background: url("../../assets/about1.png") no-repeat center center;
  background-size: cover;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: white;
}

#about .about1 .container .title {
  text-align: left;
}

#about .about1 .container .text,
#about .about1 .container .text2 {
  width: 50%;
}

#about .about1 .container #ctaWpp {
  padding: .5rem 3rem;
  margin-left: 0;
  margin-right: auto;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  gap: .5rem;
}

#about .about2 .container .gallery .item img {
  border-radius: 5px;
}

#about .about2 .container .text {
  text-align: center;
}

#about .about2 .container #ctaWpp {
  margin: 0 auto;
  padding: .5rem 3rem;
}

#about .about3 {
  background-color: #EBF0F6;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: .5rem;
}

#about .about3 .container .gallery .item img {
  border-radius: 5px;
}

#about .about3 .container .text {
  text-align: center;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
  padding: .5rem 3rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container #ctaWpp {
    width: 45vw;
    height: 5vh;
  }

  #about .about2 .container .gallery {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  #about .about2 .container #ctaWpp {
    margin: 0 auto;
    width: 45vw;
    height: 5vh;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  #about .about3 .container #ctaWpp {
    margin: 0 auto;
    width: 45vw;
    height: 5vh;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .text,
  #about .about1 .container .text2 {
    width: 80%;
  }

  #about .about1 .container #ctaWpp {
    width: 70vw;
    height: 5vh;
    padding: .5rem 1.5rem;
    margin: 0 auto;
  }

  #about .about2 .container #ctaWpp {
    width: 70vw;
    height: 5vh;
    padding: .5rem 1.5rem;
    margin: 0 auto;
  }

  #about .about3 .container #ctaWpp {
    width: 70vw;
    height: 5vh;
    padding: .5rem 1.5rem;
    margin: 0 auto;
  }
}