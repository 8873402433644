#termos #politica {
    padding: 1rem 2rem;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #999;
    font-size: 1.5rem;
    width: 95%;
    display: none;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    margin: 2.5rem;
}

#termos #politica .fec {
    display: flex;
    justify-content: space-between;
}

#termos #politica .fec .close {
    background-color: transparent;
    font-size: 1.3rem;
    cursor: pointer;
}

#termos #politica #tp {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#termos #politica #tp p {
    text-align: left;
    font-size: 1rem;
}

#termos #politica #tp p strong {
    display: block;
    font-size: 1.2rem;
}

#termos #politica #tp .acc {
    text-align: center;
    margin-top: 2rem;
}

#termos #lgpd {
    position: fixed;
    width: 100vw;
    z-index: 2;
    bottom: .5rem;
    font-size: .9rem;
    text-align: center;
}

#termos #lgpd .lei {
    padding: 1rem 0;
    background-color: #091930;
    font-size: .75rem;
    color: #fff;
    border-radius: 10px;
    width: 60%;
    margin: 0 auto;
}

#termos #lgpd .lei .pol {
    color: #fff;
    text-decoration: underline;
    transition: all .2s ease-in-out;
    cursor: pointer;
    background-color: transparent;
}

#termos #lgpd .lei .pol:hover {
    color: #fff;
    font-weight: bold;
}

#termos #politica #tp .btna,
#termos #lgpd .btna {
    padding: .5rem 1rem;
    background-color: green;
    border-radius: 5px;
    margin-left: 1rem;
    cursor: pointer;
    transition: var(--transition);
    color: white;
}

#termos #politica #tp .btna:hover,
#termos #lgpd .btna:hover {
    background-color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #termos #politica {
        padding: 1rem 1rem;
        margin: 1rem;
    }

    #termos #lgpd .lei {
        width: 95%;
        font-size: .8rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) { 
    #termos #politica {
        padding: 1rem 1rem;
        margin: 0.5rem;
    }

    #termos #politica .fec {
        align-items: center;
    }

    #termos #politica .fec h1 { 
        font-size: 1.4rem;
    }

    #termos #politica #tp p strong {
        font-size: 1.1rem;
    }

    #termos #lgpd .lei {
        width: 99%;
    }

    #termos #lgpd .btna {
        margin-top: .5rem;
    }
}