#menu .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;   
}

#menu .container .left {
    position: absolute;
    text-align: left;
    top: 2vh;
    left: 7vw;
    width: 13%;
}

#menu .container .right {
    display: flex;
    gap: 1rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container .left {
        width: 20%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .left {
        width: 30%;
        left: 2vw;
        top: 4vh;
    }

    #menu .container .right {
        flex-direction: column;
        gap: .5rem;
    }
}