#content .content1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#content .content1 .container .gallery .item {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
}

#content .content1 .container .gallery .item .text {
  font-weight: 700;
  font-size: 1.1rem;
  text-align: center;
  width: 100%;
}

#content .content1 .container .gallery .image img {
  max-width: 50%;
}

#content .content2 {
  background-color: #0B3D6B;
}

#content .content2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .content2 .container .title {
  color: white;
}

#content .content2 .container .subtitle {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
}

#content .content2 .container .bottom {
  display: flex;
  gap: 1rem;
  margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 .container .gallery {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container .gallery {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}