#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 640px;
}

#header .header1 .container {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

#header .header1 .container #ctaWpp {
    text-align: left;
    margin-left: -1.8vw;
}

#header .header2 {
    background-color: #084893;
}

#header .header2 .container .title {
    color: white;
    display: flex;
    justify-content: center;
    gap: 2rem;
    text-transform: initial;
}

#header .header2 .container .title p {
    font-size: 1.5rem;
}

#header .header3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .subtitle {
    font-size: 1.2rem;
    color: #535559;
}

#header .header3 .container .banner {
    width: 100vw;
    margin-left: -10vw;
    background: url("../../assets/header3.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    height: 60vh;
    padding-bottom: 1rem;
}

#header .header3 .container .banner .logo img {
    width: 15%;
}

#header .header3 .container .banner .title {
    color: white;
    width: 60%;
    margin: 0 auto;
}

#header .header3 .container .text {
    text-align: center;
}

#header .header3 .container .text2 {
    color: #0277E2;
    font-size: 1.5rem;
    text-align: center;
}

#header .header3 .container .gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
}

#header .header3 .container .gallery .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#header .header3 .container .gallery .item .text {
    font-weight: 900;
    font-size: 1.2rem;
}

#header .header3 .container .gallery .image img {
    max-width: 50%;
}

#header .header3 .container #ctaWpp {
    margin: 0 auto;
    padding: .5rem 3rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 60vh;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
        width: 45vw;
        height: 5vh;
    }

    #header .header2 {
        margin-top: -1rem;
    }

    #header .header2 .container .title {
        flex-direction: column;
        gap: 0;
    }

    #header .header2 .container .title .bar {
        display: none;
    }

    #header .header3 .container .banner {
        margin-left: -7.5vw;
        background: url("../../assets/mobile/header3.png") no-repeat center center;
        background-size: cover;
        height: 100%;
        padding-bottom: 2rem;
    }

    #header .header3 .container .banner .logo img {
        width: 20%;
    }

    #header .header3 .container .banner .title {
        color: white;
        width: 80%;
        margin: 0 auto;
    }

    #header .header3 .container .gallery {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 1rem;
    }

    #header .header3 .container #ctaWpp {
        margin: 0 auto;
        width: 45vw;
        height: 5vh;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 40vh;
    }

    #header .header1 .container #ctaWpp {
        width: 70vw;
        height: 5vh;
    }

    #header .header2 {
        margin-top: 0;
    }

    #header .header2 .container .title p {
        font-size: 1.3rem;
    }

    #header .header3 .container .banner .title {
        color: white;
        width: 60%;
        margin: 0 auto;
    }

    #header .header3 .container .banner {
        margin-left: -2.5vw;
    }

    #header .header3 .container .banner .logo img {
        width: 30%;
    }

    #header .header3 .container .banner .title {
        width: 100%;
    }

    #header .header3 .container .gallery .image img {
        width: 90px;
        height: 60px;
    }

    #header .header3 .container .gallery .item .text {
        font-size: 1rem;
    }

    #header .header3 .container #ctaWpp {
        width: 70vw;
        height: 5vh;
        padding: .5rem 1.5rem;
    }
}