#ctaTel {
    background-color: #0F85DB;
    font-weight: 700;
    padding: .5rem .5rem;
    border-radius: 5px;
    height: 7vh;
}

#ctaTel a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaTel a figure {
    width: 13%;
}

#ctaTel a figure img {
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaTel {
        height: 4vh;
    }

    #ctaTel a figure {
        width: 10%;
    }
    
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}